import company_logo from '../../assets/problemsolveradmin-logo.webp'
// import company_logo from '../../assets/problemsolveradmin-logo-temp-192x192.webp'

import { Link, NavLink } from 'react-router-dom'
import DropdownNav from '../dropdownNav/DropdownNav'
import { useAuth } from '../../context/AuthContext'

const Header = () => {
  const { currentAdmin } = useAuth()

  return (
    <header className='header'>
      <div className='header-container'>
        {/* Logo */}
        <Link to="/" className="header-logo-link">
            <img src={company_logo} alt="The ProblemSolver company logo" className='header-logo' />
        </Link>

        <div className="header-button-container">
          
          {/* MyAccount button */}
          <Link to={`/list/admins/${currentAdmin._id}`} className="account-status btn">
            <p><span className='text-secondary'>Account: </span>'{currentAdmin.username}'</p>
            <p><span className='text-secondary'>Permissions: </span>'{currentAdmin.permissions}'</p>
          </Link>
          
          {/* <NavLink to='/account/general' 
            className={({ isActive }) => isActive ? 'header-link btn hide-in-tablet-view isActive' : 'header-link btn hide-in-tablet-view'}
          >
            My Account
          </NavLink> */}

          {/* Logout button */}
          <NavLink to={'/logout'} className='header-link btn hide-in-tablet-view'>Logout</NavLink>

          {/* Navigation Menu */}
          <DropdownNav />
        </div>
      </div>
    </header>
  )
}

export default Header