import { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { getProblemSolution, deleteProblemSolution, toggleHideProblemSolution } from '../../../hooks/problemSolutionHooks'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'


const PostDetails = () => {
    const { ps_id } = useParams()
    const [postDetails, setPostDetails] = useState()
    const [showProblem, setShowProblem] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleted, setIsDeleted] = useState(false)
    const { authCustomFetch, currentAdmin } = useAuth()

    const isHidden = postDetails?.isHidden

    const fetchPostDetails = async () => {
        setIsLoading(true)
        setShowProblem(true)

        const data = await getProblemSolution(ps_id, authCustomFetch)
        // console.log(data)

        // setPostDetails(data.list[0])
        setPostDetails(data?.list?.length === 1 ? data.list[0] : null)

        setIsLoading(false)
    }

    useEffect(() => {
        fetchPostDetails()
    }, [ps_id])

    // Deletion Handler
    const handleDeleteProblemSolution = async () => {
        const reason = window.prompt(`Please enter the reason for deleting this ProblemSolution.\nWARNING: This will also delete ALL associated comments and replies!`)
        if (reason) {
            const response = await deleteProblemSolution(ps_id, reason, authCustomFetch)

            // navigate('/list/comments', {replace: true})
            if (response.success) setIsDeleted(true)
            else alert(`Error deleting ProblemSolution: ${response.message || response.error}`)
        }
    }

    // Hiding Handler
    const handleToggleHideProblemSolution = async () => {
        const confirmation = isHidden ? window.confirm(`Are you sure you want to un-hide this ProblemSolution?`) : prompt(`Please enter the reason for hiding this ProblemSolution.`)

        if (confirmation) {
            const reason = isHidden ? null : confirmation
            await toggleHideProblemSolution(ps_id, reason, authCustomFetch)
            fetchPostDetails()
        }
    }

    return (
        // Load Screen
        isLoading || !postDetails ? 
        <div className="page">
            {isLoading ? 
                <Spinner />

                :

                <h1>{`Post not found`}</h1>
            }
        </div> 
        
        :

        // Page
        <div className='postdetails-page page'>
            <header>
                <h2>Post Details</h2>
                <span className='text-secondary'>Showing ID: <span>'{postDetails?._id}'</span></span>
            </header>

            <div className="postdetails-container">

                <section className="postdetails-overview list-section">
                    <h4>Overview</h4>

                    <hr />

                    <ul>
                        <li><span>_id</span> <span>{postDetails?._id}</span></li>
                        {/* <li><span>Title</span> <span>{postDetails?.title}</span></li> */}
                        <li><span><NavLink to={`/list/users/${postDetails?.user_id}`}>Author</NavLink></span> <span>{postDetails?.username}</span></li>
                        <li><span>Date</span> <span>{postDetails?.date}</span></li>
                        <li><span><NavLink to={`/list/comments`}>Comments</NavLink></span> <span>{postDetails?.comments}</span></li>
                        <li><span>Likes</span> <span>{postDetails?.likes}</span></li>                        
                    </ul>
                </section>


                {/* <section className="postdetails-activity list-section">
                    <h4>Activity</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/`}>Comments</NavLink></span> <span>{postDetails?.commentCount}</span></li>
                        <li><span>Likes</span> <span>{postDetails?.likesGiven}</span></li>
                    </ul>
                </section> */}


                <section className="postdetails-moderation">
                    <h4>Moderation Controls</h4>

                    <hr />

                    {isDeleted ? <h1 className='text-danger'>{`Post has been Deleted`}</h1>
                    
                    :

                    <ul>
                        <li>
                            <button className={`${isHidden ? 'button-success' : 'button-danger'}`} onClick={handleToggleHideProblemSolution}>{isHidden ? 'Un-hide' : 'Hide'} Post</button> {/* Deactivate account and hide from public view (Cannot post, comment, etc) */}
                            <p className="text-secondary">This will hide the post from public view and prevent it from being seen by other users.</p>
                        </li>
                        <li>
                            <button className="button-danger" onClick={handleDeleteProblemSolution} disabled={!currentAdmin.isAdmin}>Delete Post</button>
                            <p className="text-secondary">This will delete the post and all associated comments.</p>
                        </li>
                    </ul>}
                </section>


                <section className="postdetails-content">
                    <h4>Content</h4>

                    <hr />

                    {/* <div className='account-posts-categories'>
                        <NavLink to={`/`} className={`account-posts-categories-link ${category === 'all' ? 'isActive' : ''}`}>All Posts</NavLink>
                        <NavLink to={`/`} className={`account-posts-categories-link ${category === 'deleted' ? 'isActive' : ''}`}>Pending Deletion</NavLink>
                    </div> */}

                    <p className='postdetails-content-title'><span className='text-secondary'>Title:</span> {postDetails?.title}</p>
                    
                    <div className="postdetails-content-controls">
                        <button onClick={() => setShowProblem(true)} className={`button-small ${showProblem ? 'toggled' : ''}`}>Problem</button>
                        <button onClick={() => setShowProblem(false)} className={`button-small ${showProblem ? '' : 'toggled'}`}>Solution</button>
                    </div>

                    <div className='postdetails-content-text'>
                        
                        {/* <div className="postdetails-content-problem">
                            <p className='postdetails-content-title'>The Problem</p> */}

                            <div className="postdetails-content-text-container" dangerouslySetInnerHTML={{ __html: (showProblem ? postDetails?.problem : postDetails?.solution) }}>
                                {/* <p className='problemsolution-problem-text'>{problem}</p> */}
                                {/* <div className="postdetails-content-text" 
                                    dangerouslySetInnerHTML={{ __html: showProblem ? postDetails?.problem : postDetails?.solution }}>
                                </div> */}
                            </div>
                        {/* </div> */}

                    </div>
                    


                </section>

            </div>
        </div>
    )
}

export default PostDetails
