const baseURL = import.meta.env.VITE_BACKEND_URL

// GET
export const getAdmin = async (admin_id, authCustomFetch) => {

    try {
        const url = `${baseURL}/get_admin/${admin_id}`
        const options = {method: 'GET'}

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN ADMIN DATA RETRIEVAL:", error.message)
        return false
    }
}

// DEACTIVATE
export const togglePermissionsAdmin = async (admin_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/toggle_permissions_admin/${admin_id}`
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reason
            })
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN USER SHADOWBANNING:", error.message)
        return false
    }
}

// DELETE
export const deleteAdmin = async (admin_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/delete_admin/${admin_id}`
        const options = {
            method: 'DELETE', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN ADMIN DELETION:", error.message)
        return false
    }
}