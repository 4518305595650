import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { limitTextLength } from '../../helpers/helper'

const Login = () => {
    const location = useLocation()
    const { login, requestPasswordReset } = useAuth()
    
    const [state, setState] = useState("Login")

    const [errorMessage, setErrorMessage] = useState("")
    const [submitFailed, setSubmitFailed] = useState(false)

    const [formInput, setFormInput] = useState({
        username: "",
        permissions: "", // Moderator
        password: "", // 12345678Q@
        email: "", // vonglory176@gmail.com
    })

    // useEffect(() => {
        // if (location.pathname === '/login/reset-password') setState("Reset Password")
        // else setState("Login")
    // }, [location.pathname])

    // useEffect(() => {
    //     document.state = state
    // }, [state])

    // Handles Form Submission
    const handleSubmit = async (e) => {
        e.preventDefault()

        // Sends data to AuthContext
        let response
        if (state === "Login") {
            response = await login(formInput.email, formInput.password)
            if (response === false) setState("Email Verification")
        }

        else if (state === "Reset Password") {
            response = await requestPasswordReset(formInput.email)
            if (response.message) setState("Password Recovery Email")
        }
        
        
        // If an error is returned, set the error message
        // console.log(response) 

        if (response?.error) {
            setErrorMessage(response.error || response.message)
            setSubmitFailed(true)
        }
    }
    
    // Swaps Modal between Login and Sign Up
    useEffect(() => {
        setErrorMessage("")
        setSubmitFailed(false)
    }, [state])

    // Records form data
    const changeHandler = (e) => {
        const maxCharacters = 50
        setFormInput({...formInput, [e.target.name]: limitTextLength(e.target.value, maxCharacters)})
    }
    
    return (

    <div className='login-page'> {/* page */}

        <div className={`login-container modal-container ${state === "Email Verification" ? "show-verification-message" : ""}`}>

            {/* Header */}
            <header className="login-header">
                <h1 style={{textAlign: state === "Password Recovery Email" ? "center" : "left"}}>{state}</h1>
            </header>

            <hr />

            {state === "Email Verification" || state === "Password Recovery Email" ?

            // Email Verification Message
            <div className='login-verification-message'>
                <p>We've sent a email to your inbox at '{formInput.email}'</p>
                <p>Please check your inbox for a link to {state === "Password Recovery Email" ? "reset your password" : "verify your account"}</p>
                <p className='text-secondary'>(If you don't see it, please try logging in again in a few minutes)</p>
                <hr />

                <div className="login-state-swapper">
                    <p>Return to Login <span onClick={() => {setState("Login")}}>Click here</span></p>
                </div>
            </div>

            :

            // Login Form
            <div className='login-form-container'>

                {/* Form */}
                <form className={`login-form ${submitFailed && "login-error"}`} onSubmit={handleSubmit}>

                    {/* Email */}
                    <label htmlFor="email" className='text-secondary'>Email Address</label>
                    <input name="email" value={formInput.email} onChange={changeHandler} type="email" placeholder='Email Address' />

                    {/* Error Message (If present) */}
                    {errorMessage && <p className='login-error-text'>{errorMessage}</p>}

                    {/* Password */}
                    {state !== "Reset Password" && <label htmlFor="password" className='text-secondary'>Password</label>}
                    {state !== "Reset Password" && <input name="password" value={formInput.password} onChange={changeHandler} type="password" placeholder='Password' />}

                    <button type='submit'>Continue</button>
                    
                    <hr />
                </form>


                {/* Login / Reset-Password swapper */}
                <div className="login-state-swapper">
                    {state === "Login" ?
                    // <p>Forgot your password? <Link to="/login/reset-password">Click here</Link></p>
                    <p>Forgot your password? <span onClick={() => {setState("Reset Password")}}>Click here</span></p>

                    :

                    // <p>Return to Login <Link to="/login">Click here</Link></p>
                    <p>Return to Login <span onClick={() => {setState("Login")}}>Click here</span></p>
                    }
                </div>
            </div>}
        </div>
    </div>
    )
}

export default Login

