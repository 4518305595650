const baseURL = import.meta.env.VITE_BACKEND_URL

// GET
export const getReportLog = async (reportlog_id, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_get_report-log/${reportlog_id}`
        const options = {method: 'GET'}

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN REPORTLOG DATA RETRIEVAL:", error.message)
        return false
    }
}

// DELETE
export const deleteReportLog = async (reportlog_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_delete_report-log/${reportlog_id}`
        const options = {
            method: 'DELETE', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN REPORTLOG DELETION:", error.message)
        return false
    }
}