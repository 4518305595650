import React from 'react'
import { Link } from 'react-router-dom'

// Basic 404 page with no styling
const NotFound = () => {
  return (
    <div id="notfound">

      <div className="notfound-container">
        <h1>PAGE NOT FOUND</h1>

        <p className="notFound__message">The page you requested does not exist.</p>

        <hr />

          <Link to={"/"}>
            <button className='notFound__continue button-small'>Return to Overview</button>
          </Link>
      </div>

    </div>
  )
}

export default NotFound
