import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { limitTextLength } from '../../../../frontend/src/helpers/helper'
// import { redirectWithSearch } from '../../helpers/helper'
// import { debounce } from 'lodash'


// Searchbar component
const Searchbar = ({headerFilters}) => { // searchHeaders, sortHeaders
    const location = useLocation()
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(window.location.search)

    const [searchTerm, setSearchTerm] = useState(searchParams.get('query') || "")
    const [searchCategory, setSearchCategory] = useState(searchParams.get('category') || "")
    const [sortCategory, setSortCategory] = useState(searchParams.get('sort') || "relevance")
    const [sortOrder, setSortOrder] = useState(searchParams.get('order') || "desc")
    const [isFirstRender, setIsFirstRender] = useState(true)

    const searchbarRef = useRef(null)

    useEffect(() => {
        handleSearch()
    }, [searchCategory, sortCategory, sortOrder])

    useEffect(()=> {
        setSearchTerm(searchParams.get('query') || "")
        setSearchCategory(searchParams.get('category') || "_id")
        setSortCategory(searchParams.get('sort') || "relevance")
        setSortOrder(searchParams.get('order') || "desc")
    }, [location.search])
    
    const handleSearch = async (event) => {
        event?.preventDefault()


        searchParams.set('query', searchTerm?.trim())
        searchParams.set('category', searchCategory)
        searchParams.set('sort', sortCategory)
        searchParams.set('order', sortOrder)

        if (isFirstRender) {
            navigate(`${location.pathname}?${searchParams.toString()}`, {
                replace: true
            })
            setIsFirstRender(false)
        }
        else {
            navigate(`${location.pathname}?${searchParams.toString()}`, {
                // replace: true
            })
        }
    }
    
    return (
        <form onSubmit={handleSearch}>


            <div className={`searchbar`} ref={searchbarRef}>

                <div className="searchbar-input-container">
                    {/* Search Category */}
                    {headerFilters && 
                    <select name="searchCategory" id="searchCategory" onChange={(e) => setSearchCategory(e.target.value)} value={searchCategory}>
                        {headerFilters.searchBy?.map((header, index) => (
                            <option value={header} key={index} defaultValue={headerFilters.searchBy[0]}>{header}</option>
                        ))}
                    </select>}


                    {/* Search Input */}
                    <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(limitTextLength(e.target.value, 256))}
                    // onFocus={handleFocus}
                    // onBlur={handleBlur}
                    />


                    {/* Search Button */}
                    <button type="submit" className="searchbar-search-btn" title="Search for posts">
                        <i className="fa-solid fa-magnifying-glass store-btns__search fa-lg"></i>
                    </button>

                    {/* <Link to={`/?query=${searchTerm}`} title="Search for posts">
                        <i className="fa-solid fa-magnifying-glass store-btns__search fa-lg"></i>
                    </Link> */}
                </div>

                
                <div className="searchbar-options-container">
                    {/* Search Category */}
                    {/* <div className="searchbar-option">
                        <label>Search By</label>
                        {searchHeaders && 
                        <select name="searchCategory" id="searchCategory" onChange={(e) => setSearchCategory(e.target.value)} value={searchCategory}>
                            {searchHeaders?.map((header, index) => (
                                <option value={header} key={index} defaultValue={searchHeaders[0]}>{header}</option>
                                ))}
                        </select>}
                    </div> */}
                    
                    {/* Sort Category */}
                    <div className="searchbar-option">
                        <label>Sort By</label>
                        <select name="sortCategory" id="sortCategory" onChange={(e) => setSortCategory(e.target.value)} value={sortCategory}>
                            {/* <option value="relevance" defaultValue>Relevance</option>
                            <option value="date">Date</option> */}
                            {headerFilters?.sortBy?.map((header, index) => (
                                <option value={header} key={index}>{header}</option>
                            ))}
                        </select>
                    </div>

                    {/* Ascending/Descending */}
                    <div className="searchbar-option">
                        <label>Order By</label>
                        <select name="sortOrder" id="sortOrder" onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
                            <option value="desc" defaultValue>Desc</option>
                            <option value="asc">Asc</option>
                        </select>
                    </div>
                </div>                
            </div>

        </form>
    )
}

export default Searchbar
