import { NavLink, Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'

const DropdownNav = () => {
  // const navigate = useNavigate()

  // Only show certain links when logged-in
  // const { isLoggedIn, logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const { currentAdmin } = useAuth()

  return (
    <nav className={`dropdownnav show-in-tablet-view ${isOpen? 'open' : ''}`} onBlur={() => setIsOpen(false)}>
      <div className='dropdownnav-container'>
        {/* Open/Close Button */}
        <button aria-label='Toggle Dropdown Navigation' name='dropdown-nav-toggle' className='dropdownnav-button' onClick={() => setIsOpen(!isOpen)}>
            {/* Navigation <i className="fa-solid fa-caret-down"></i> */}
            <i className='fa-solid fa-bars fa-lg'></i>
        </button>

        {/* Dropdown Navigation Menu */}
        <div className={`dropdownnav-content`}>
            <ul className='dropdownnav-navlink-list'>
              
              {/* Account */}
              <h5 className='text-secondary'>Account</h5>

              <li>
                <NavLink to={`/list/admins/${currentAdmin._id}`} 
                className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                >My Account</NavLink>
              </li>

              {/* <li>
                <NavLink to='/logout' className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}>Logout</NavLink>
              </li> */}


              {/* General */}
              <h5 className='text-secondary'>General</h5>

              <li>
                <NavLink 
                  to='/'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Overview
                </NavLink>
              </li>

              
              {/* Frontend */}
              <h5 className='text-secondary'>Front-End</h5>

              <li>
                <NavLink 
                  to='/list/users'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Users
                </NavLink>
              </li>

              <li>
                <NavLink 
                  to='/list/posts'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Posts
                </NavLink>
              </li>

              <li>
                <NavLink 
                  to='/list/comments'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Comments
                </NavLink>
              </li>

              {/* Backend */}
              <h5 className='text-secondary'>Back-End</h5>

              <li>
                <NavLink 
                  to='/list/report-logs?query=&category=_id&sort=relevance&order=desc&page=1'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Report-Logs
                </NavLink>
              </li>             
              <li>
                <NavLink 
                  to='/list/moderation-logs?query=&category=_id&sort=relevance&order=desc&page=1'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Mod-Logs
                </NavLink>
              </li>             
              <li>
                <NavLink 
                  to='/list/admins?query=&category=_id&sort=relevance&order=desc&page=1'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Admins
                </NavLink>
              </li>                  
              <li>
                <NavLink 
                  to='/register'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Register
                </NavLink>
              </li>
              <li>
                <NavLink to='/logout' className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}>Logout</NavLink>
              </li>
            </ul>
        </div>
      </div>
    </nav>
  )
}

export default DropdownNav
