import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const AuthContext = createContext(null)
export const useAuth = () => useContext(AuthContext)

/* THIS FILE MANAGES THE STATE OF THE APPLICATION

    This includes:
    - Modal Display State
    - Tokens
    - User State
*/

export const AuthContextProvider = (props) => {
    const baseURL = import.meta.env.VITE_BACKEND_URL
    const location = useLocation()
    const navigate = useNavigate()

    const [adminAuthToken, setAdminAuthToken] = useState(sessionStorage.getItem('adminAccessToken'))
    // const [currentModal, setCurrentModal] = useState(false) // Modal State
    const [isLoggedIn, setIsLoggedIn] = useState(null) // authToken ? true : false
    const [currentAdmin, setCurrentAdmin] = useState({
      // username: sessionStorage.getItem('admin'),
      // permissions: sessionStorage.getItem('permissions'),
    })

    // Maintains login status between pages
    const checkLoginStatus = async () => {
      // const token = sessionStorage.getItem('accessToken')
      // const user = sessionStorage.getItem('user')

      // // If there are any inconsistencies, refresh the status
      // if ((!isLoggedIn && (token || user)) || (isLoggedIn && (!token || !user))) {
      if (!location.pathname.includes('logout')) {
        const response = await generateAccessToken()
        if (response !== null) logout()
      }
    }

    useEffect(() => { 
      checkLoginStatus()
      // closeModal()
    }, [location.pathname])

    // Modal Stuff -------------------------------------------------------------------------------------

    // const openModal = (modalName) => {
    //   setCurrentModal(modalName)
    // }
    // const closeModal = () => {
    //   setCurrentModal(false)
    // }

    // Token Stuff -------------------------------------------------------------------------------------

    // TOKEN STATE REFRESH (For SETTING the sessions access token)
    const updateAuthState = (data) => {
      // console.log("Updating information!", data)

      // If a token is given, set it and the user
      if (data.adminAccessToken) {
        sessionStorage.setItem('adminAccessToken', data.adminAccessToken)
        
        // Update the admin state
        if (data.admin) {
          const {permissions} = data.admin
          // console.log(permissions)

          const isOwner = (permissions === "Owner")
          const isAdmin = (permissions === "Admin") || (permissions === "Owner")
          const isModerator = (permissions === "Moderator") || (permissions === "Admin") || (permissions === "Owner")

          setCurrentAdmin({
            ...data.admin, 
            isOwner, 
            isAdmin, 
            isModerator
          })
        }
      }
      // If no token is given, remove both it and the user
      else { 
        sessionStorage.removeItem('adminAccessToken')
        // sessionStorage.removeItem('admin')
        // sessionStorage.removeItem('permissions')
      }

      // Update the session state
      setAdminAuthToken(data.adminAccessToken)
      setIsLoggedIn(data.adminAccessToken? true : false)
    }

  //   // TOKEN GENERATION (For GETTING/refreshing the sessions access token)
    const generateAccessToken = async () => {
      // This should call your backend's refresh token endpoint
      // and update the sessionStorage with the new access token
      const response = await fetch(`${baseURL}/admin_generate_access_token`, {
          method: 'GET',
          credentials: 'include',
      })

      const data = await response.json()
      // console.log(data)
  
      if (data.adminAccessToken) {
          updateAuthState({adminAccessToken: data.adminAccessToken, admin: data.admin})
          // sessionStorage.setItem('accessToken', data.accessToken)
  
          return null
      }
      else {
          // console.log(data.error)
          return data.error
      }
  }
      
  // CUSTOM FETCH CALL FOR AUTHORIZATION REQUIREMENTS
  async function authCustomFetch(url, options = {}) {
    
    // Inject the Authorization header with the access token
    const adminAccessToken = sessionStorage.getItem('adminAccessToken')
    
    if (adminAccessToken) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${adminAccessToken}`,
        }
    }

    let response = await fetch(url, options) // First request

    // If unauthorized, try refreshing the token and retrying the request once
    if (response.status === 403) {
      await generateAccessToken()
      const newAdminAccessToken = sessionStorage.getItem('adminAccessToken')

      if (newAdminAccessToken) { // If a new token was given, re-send the request
          options.headers.Authorization = `Bearer ${newAdminAccessToken}`
          response = await fetch(url, options)          
      }
      else if (isLoggedIn){ // Otherwise (refresh-token was lost/expired), logout and send user to login/signup
        logout()
        // window.location.replace("/?login")
      }
    }

    // if (!response.ok) throw new Error(response.statusText)
    // if (!response.ok) {console.error("ERROR WHILE FETCHING: " + response.statusText)}

    // else {
      const data = await response.json()
      return data
    // }
  }
    
  // Admin Login Related -------------------------------------------------------------------------------------
  
  // ADMIN SIGNUP
  const signup = async (username, permissions, email, password) => {
      const data = await authCustomFetch(`${baseURL}/admin_signup`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, permissions, email, password })
      })
      
      // console.log(data)
      const {success, adminAccessToken, admin, error} = data
      
      if (!success) {
        // console.error("ERROR WHILE SIGNING UP: " + error)
        return error
      }

      else if (adminAccessToken) {
          // Store the access token in session storage
          // updateAuthState({accessToken: data.accessToken, user: data.user})
          // sessionStorage.setItem('user', data.user)
  
          // window.location.replace("/")
          // navigate("/", { replace: true }) // Redirecting to home
          return null
      }

      else if (admin.isEmailVerified === false) return false
  }
  
  // ADMIN LOGIN
  const login = async (email, password) => {
  
      const response = await fetch(`${baseURL}/admin_login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ email, password })
      })
  
      const data = await response.json()
      const {success, adminAccessToken, admin, error} = data
      // console.log(data)
      
      if (!success) {
        // console.error("ERROR WHILE LOGGING IN: " + error)
        return data
      }

      else if (adminAccessToken) {
          // Store the access token in session storage
          updateAuthState({adminAccessToken: adminAccessToken, admin: admin})
          return null
      }

      else if (admin.isEmailVerified === false) return false
  }  
      
  // USER LOGOUT
  const logout = async () => {
      // Remove the access token from session storage
      // sessionStorage.removeItem('user')
      
      // Call the backend to invalidate the refresh token
      const response = await fetch(`${baseURL}/admin_logout`, {
        method: 'POST',
        credentials: 'include',
        // Cookie is automatically sent
      })
      
      if (response.ok) {
        updateAuthState({adminAccessToken: null, admin: null})
        // window.location.replace("/")
        // navigate("/", { replace: true })
      }
      else {
        // console.error("ERROR WHILE LOGGING OUT")
        // return "ERROR WHILE LOGGING OUT"
      }
  }

  // VERIFY EMAIL
  const verifyEmail = async (token) => {
    const response = await fetch(`${baseURL}/admin_verify_email?token=${encodeURIComponent(token)}`, {
      method: 'PUT',
      credentials: 'include',
    })

    const data = await response.json()
    // console.log(data)

    return data
  }

  // REQUEST PASSWORD RESET
  const requestPasswordReset = async (email) => {
    const response = await fetch(`${baseURL}/admin_request_password_reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    })

    const data = await response.json()
    // console.log(data)

    if (data.success) {
      // console.log("Email sent successfully")
      return {
        success: true,
        message: "Email sent successfully"
      }
    }
    else {
        // console.error("ERROR WHILE REQUESTING PASSWORD RESET: " + data.error)
        return data.error
    }
  }

    // VERIFY PASSWORD RESET TOKEN
    const verifyPasswordResetToken = async (token) => {
      const response = await fetch(`${baseURL}/admin_verify_password_reset_token?token=${encodeURIComponent(token)}`, {
        method: 'GET',
      })
  
      const data = await response.json()
      // console.log(data)
  
      return data
    }

  // RESET PASSWORD
  const resetPassword = async (token, newPassword) => {
    const response = await fetch(`${baseURL}/admin_reset_password`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({ token, newPassword })
    })

    const data = await response.json()
    // console.log(data)

    return data
  }

  // Analytics Related -------------------------------------------------------------------------------------

  const getGenericAnalytics = async () => {
    const response = await fetch(`${baseURL}/get_generic_analytics`, {
      method: 'GET',
    })

    
    if (response.ok) {
      const data = await response.json()
      // console.log(data)
  
      return data
    }
    else {
      // console.error("ERROR WHILE GETTING GENERIC ANALYTICS")
    }
  }



  // -------------------------------------------------------------------------------------
  
  const contextValue = {
    isLoggedIn,
    checkLoginStatus,
    currentAdmin,
    
    authCustomFetch: (url, options) => authCustomFetch(url, options, generateAccessToken),

    login,
    signup,
    logout,

    verifyEmail,
    requestPasswordReset,
    verifyPasswordResetToken,
    resetPassword,
    
    getGenericAnalytics,
  }

  return (
  <AuthContext.Provider value={contextValue}>
      {props.children}
  </AuthContext.Provider>
  )
}