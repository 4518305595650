import { RegExpMatcher, TextCensor, englishDataset, englishRecommendedTransformers, } from 'obscenity'

const matcher = new RegExpMatcher({
	...englishDataset.build(),
	...englishRecommendedTransformers,
})

export const filterBadWords = (text) => {
    if (matcher.hasMatch(text)) return matcher.censor(text)

    return text
}

export const containsBadWords = (text) => {
    return Boolean(matcher.hasMatch(text))
}

export const closeModal = (navigate) => {
    // const { setDisplayModal } = useAuth()
    const searchParams = new URLSearchParams(window.location.search)
    // searchParams.delete('modal')
    
    navigate("/?" + searchParams.toString())
    // navigate(-1)
    // navigate(window.location.pathname + '?' + searchParams.toString())
}

export const redirectWithParams = (event, navigate) => {
    event.preventDefault() // Prevent the default link behavior

    const href = event.currentTarget.href
    const searchParams = new URLSearchParams(window.location.search)
    
    const url = new URL(href, window.location.origin)
    url.search = searchParams.toString() // Set the search params to the existing ones

    navigate(url.toString()) // Navigate to the new URL with preserved parameters
}

export const redirectWithSearch = (navigate, searchQuery) => {
    const searchParams = new URLSearchParams(window.location.search)

    if (!searchQuery.trim()) searchParams.delete('query')
    else searchParams.set('query', searchQuery)

    navigate(`/?${searchParams.toString()}`)
}

export const validatePassword = (password, confirmPassword) => {
    const hasEightCharacters = password.length >= 8
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password)
    const hasUpperCase = /[A-Z]/.test(password)
    const hasNumber = /[0-9]/.test(password)

    // Bypass confirmation if second password is null
    const passwordsMatch = confirmPassword === null || (password === confirmPassword)

    // console.log(
    //     password,
    //     confirmPassword,
    //     passwordsMatch
    // )

    if (!hasEightCharacters) {
        return { success: false, message: "Password must be at least 8 characters long." }
    }
    if (!hasSpecialCharacter) {
        return { success: false, message: "Password must contain at least one special character." }
    }
    if (!hasUpperCase) {
        return { success: false, message: "Password must contain at least one uppercase letter." }
    }
    if (!hasNumber) {
        return { success: false, message: "Password must contain at least one number." }
    }
    if (!passwordsMatch) {
        return { success: false, message: "Passwords do not match." }
    }

    return { success: true, message: "Password is valid." }
}

export const isEditorContentEmpty = (content) => {
    // This function checks if the content only has empty tags like <p><br></p>
    const div = document.createElement('div')
    div.innerHTML = content
    return div.textContent.trim() === '' && div.querySelectorAll('img, video, iframe').length === 0
}

export const getTextCount = (htmlContent) => {
    const div = document.createElement('div')
    div.innerHTML = htmlContent
    const textLength = div.textContent.length // Counting whitespace by removing trim()
    return textLength
}

export const removeExtraTextPBrs = (htmlContent) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, 'text/html')
  
    // Reduce sequences of more than three consecutive <p><br></p> tags to exactly three
    doc.body.innerHTML = doc.body.innerHTML.replace(/(<p><br><\/p>\s*){4,}/gi, '<p><br></p><p><br></p><p><br></p>')

    return doc.body.innerHTML // Return the modified HTML content
}

export const limitTextLength = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) : text;
}

export const adjustTextAreaHeight = (textArea) => {
    textArea.style.height = 'auto'
    textArea.style.height = (textArea.scrollHeight) + 'px'
}

export const isOverflowing = (element) => {
    // console.log(element.scrollHeight, element.clientHeight)
    return element.scrollHeight > element.clientHeight
}

// export const openModal = (navigate, modalName) => {

//     // Set "login" query parameter
//     const searchParams = new URLSearchParams(window.location.search)
//     searchParams.set('modal', modalName)
    
//     // Navigate to the same path with updated search parameters
//     navigate(`${window.location.pathname}?${searchParams.toString()}`)
// }

// Basic Fetch Utility
// export const fetchUtil = async (url, options = {}, authCustomFetch) => {
//     // Set default headers
//     const headers = {
//         'Content-Type': 'application/json',
//         ...options.headers
//     }

//     // Prepare the fetch options
//     const fetchOptions = {
//         ...options,
//         headers
//     }

//     try {
//         // const response = authCustomFetch? await authCustomFetch(url, fetchOptions) 
//         // : await fetch(url, fetchOptions)
//         const response = await fetch(url, fetchOptions)
        
//         const data = await response.json()

//         if (!response.ok) {
//             throw new Error(data.error || 'Network response was not ok')
//         }

//         return data
//     } catch (error) {
//         console.error('Fetch Error:', error.message)
//         throw error  // Re-throw to allow caller to handle
//     }
// }