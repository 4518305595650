const baseURL = import.meta.env.VITE_BACKEND_URL

// GET
export const getModerationLogs = async (ml_id, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_get_moderation-log/${ml_id}`
        const options = {method: 'GET'}

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN ADMIN DATA RETRIEVAL:", error.message)
        return false
    }
}

// DELETE
export const deleteModerationLog = async (ml_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_delete_moderation-log/${ml_id}`
        const options = {
            method: 'DELETE', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN MODERATIONLOG DELETION:", error.message)
        return false
    }
}