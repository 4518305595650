import { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { getComment, deleteComment, toggleHideComment } from '../../../hooks/commentHooks'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'



const CommentDetails = () => {
    // const navigate = useNavigate()

    const { comment_id } = useParams()
    const [commentDetails, setCommentDetails] = useState()
    const [isDeleted, setIsDeleted] = useState(false)
    const { authCustomFetch, currentAdmin } = useAuth()
    const [isLoading, setIsLoading] = useState(true)

    const isComment = !commentDetails?.parent_id
    const isHidden = commentDetails?.isHidden
    const commentType = isComment ? 'Comment' : 'Reply'

    const fetchCommentDetails = async () => {
        setIsLoading(true)

        // Get Comment
        const data = await getComment(comment_id, authCustomFetch)
        // console.log(data)

        // Set Comment Details
        setCommentDetails(data?.list?.length === 1 ? data.list[0] : null)

        setIsLoading(false)
    }

    useEffect(() => {        
        fetchCommentDetails()
    }, [comment_id])

    // Deletion Handler
    const handleDeleteComment = async () => {
        const reason = window.prompt(`Please enter the reason for deleting this ${commentType}.\nWARNING: This will also delete ALL associated replies.`)
        if (reason) {
            const response = await deleteComment(comment_id, reason, authCustomFetch)

            // navigate('/list/comments', {replace: true})
            if (response.success) setIsDeleted(true)
            else alert(`Error deleting Comment: ${response.message || response.error}`)
        }
    }

    // Hiding Handler
    const handleToggleHideComment = async () => {
        const confirmation = isHidden ? window.confirm(`Are you sure you want to un-hide this ${commentType}?`) : prompt(`Please enter the reason for hiding this ${commentType}.`)

        if (confirmation) {
            const reason = isHidden ? null : confirmation
            await toggleHideComment(comment_id, reason, authCustomFetch)
            fetchCommentDetails()
        }
    }

    return (
        // Load Screen
        isLoading || !commentDetails ? 
        <div className="page">
            {isLoading ? 
                <Spinner />

                :

                <h1>Comment not found</h1>
            }
        </div> 
        
        :

        // Main Page
        <div className='commentdetails-page page'>
            <header>
                <h2>{commentType} Details</h2>
                <span className='text-secondary'>Showing ID: <span>'{commentDetails?._id}'</span></span>
            </header>

            <div className="commentdetails-container">

                <section className="commentdetails-overview list-section">
                    <h4>Overview</h4>

                    <hr />

                    <ul>
                        <li><span>_id</span> <span>{commentDetails?._id}</span></li>
                        <li><span><NavLink to={`/list/users/${commentDetails?.user_id}`}>Author</NavLink></span> <span>{commentDetails?.username}</span></li>
                        <li><span>Date</span> <span>{commentDetails?.date}</span></li>
                        <li><span>Type</span> <span>{commentType}</span></li>

                        <li><span><NavLink to={`/list/comments?query=${commentDetails?._id}&category=parent_id&sort=relevance&order=desc`}>Replies</NavLink></span> <span>{commentDetails?.replies}</span></li>
                        
                        <li><span>Likes</span> <span>{commentDetails?.likes}</span></li>
                        <li><span>Hidden</span> <span>{commentDetails?.isHidden ? 'True' : 'False'}</span></li>
                    </ul>
                </section>


                {/* <section className="postdetails-activity list-section">
                    <h4>Activity</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/`}>Comments</NavLink></span> <span>{postDetails?.commentCount}</span></li>
                        <li><span>Likes</span> <span>{postDetails?.likesGiven}</span></li>
                    </ul>
                </section> */}


                <section className="commentdetails-moderation">
                    <h4>Moderation Controls</h4>

                    <hr />

                    {isDeleted ? <h1 className='text-danger'>{`${commentType} has been Deleted`}</h1>
                    
                        :

                        <ul>
                            <li>
                                <button className={`${isHidden ? 'button-success' : 'button-danger'}`} onClick={handleToggleHideComment}>{isHidden ? 'Un-Hide' : 'Hide'} {commentType}</button> {/* Deactivate account and hide from public view (Cannot post, comment, etc) */}
                                <p className="text-secondary">This will hide the post from public view and prevent it from being seen by other users.</p>
                            </li>
                            <li>
                                <button className="button-danger" onClick={handleDeleteComment} disabled={!currentAdmin.isAdmin}>Delete {commentType}</button>
                                <p className="text-secondary">This will delete the post and all associated comments.</p>
                            </li>
                        </ul>
                    }

                </section>


                <section className="postdetails-content">
                    <h4>Content</h4>

                    <hr />

                    <div className='commentdetails-content-text'>

                            <div className="commentdetails-content-text-container" dangerouslySetInnerHTML={{ __html: commentDetails?.text}} />

                    </div>
                    


                </section>

            </div>
        </div>
    )
}

export default CommentDetails
