const baseURL = import.meta.env.VITE_BACKEND_URL

export const getListViewData = async (selectedCollection, authCustomFetch, searchParams) => {
    const { query="", category="_id", sort="date", order="desc", page=1 } = searchParams
    // console.log(searchParams)

    try {
        const url = `${baseURL}/admin_get_${selectedCollection}?query=${encodeURIComponent(query)}&category=${encodeURIComponent(category)}&sort=${encodeURIComponent(sort)}&order=${encodeURIComponent(order)}&page=${encodeURIComponent(page)}`
        const options = {method: 'GET'}

        const data = await authCustomFetch(url, options)
        // console.log(data)
        return data

    } catch (error) {
        // console.error("ERROR IN LIST VIEW DATA RETRIEVAL:", error.message)
        return false
    }
}