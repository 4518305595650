import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useAuth } from './context/AuthContext'

import './styles/index.css'

// Components
import Header from './components/header/Header'
import SideNav from './components/sideNav/SideNav'

// Pages
import Overview from './pages/overview/Overview'
import NotFound from './pages/notFound/NotFound'
import LoginSignup from './pages/login/Login'
// import LoginSignup from './pages/login/LoginSignup'
import ListView from './pages/listView/ListView'
import UserDetails from './pages/detailView/userDetails/UserDetails'
import PostDetails from './pages/detailView/postDetails/PostDetails'
import CommentDetails from './pages/detailView/commentDetails/CommentDetails'
import ReportLogDetails from './pages/detailView/reportLogDetails/ReportLogDetails'
import ModerationLogDetails from './pages/detailView/moderationLogDetails/ModerationLogDetails'
import AdminDetails from './pages/detailView/adminDetails/AdminDetails'
import Login from './pages/login/Login'
import SignUp from './pages/signUp/SignUp'
import VerifyEmail from './pages/verifyEmail/VerifyEmail'
import ResetPassword from './pages/resetPassword/ResetPassword'

import Spinner from './components/spinner/Spinner'

const App = () => {
  const {checkLoginStatus, isLoggedIn, currentAdmin, logout} = useAuth()
  // const [isFirstLoad, setIsFirstLoad] = useState(true)
  // const [isLoading, setIsLoading] = useState(true)

  const {isAdmin, isModerator, isOwner} = currentAdmin

  // Check login status before first rendering the app
  // useEffect(() => {
  //   if (isLoading) { // isFirstLoad
  //     console.log("FIRST LOAD: Checking login status")
  //     setIsLoading(true)

  //     const checkStatus = async () => {
  //       const response = await checkLoginStatus()
  //       console.log(response)

  //       // setIsFirstLoad(false)
  //       setIsLoading(false)
  //     }
  //     checkStatus()
  //   }
  // }, [isLoading]) // isFirstLoad

  function LogoutHandler() {
    console.log("Logout")
    logout()
  }


  return (
    
    // Checking login-status... (Waiting to load App)
    isLoggedIn === null ? 

    <div className="App" style={{height: '100vh'}}>
      <Spinner />
    </div>

    :

    // ...User not logged in...
    !isLoggedIn ?
    
    <div className='App' style={{height: '100vh'}}>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/reset-password" element={<ResetPassword />} /> Reset Password */}
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
    </div>
    
    :

    // ...Otherwise, load Main Application
    <div className='App'>
      <Header />
      
      
      <div className='main-content'>
        <SideNav />


        <Routes>

          {/* Overview */}
          <Route path='/' element={<Overview />} />

          <Route path='/list/:collection' element={<ListView />}/>

          <Route path='/list/users/:user_id' element={<UserDetails />}/>
          <Route path='/list/posts/:ps_id' element={<PostDetails />}/>
          <Route path='/list/comments/:comment_id' element={<CommentDetails />}/>
          <Route path='/list/report-logs/:reportlog_id' element={<ReportLogDetails />}/>

          {!currentAdmin.isAdmin && <Route path='/list/admins' element={<Navigate to="/" replace />}/>}
          <Route path='/list/admins/:admin_id' element={<AdminDetails />}/>

          {!currentAdmin.isAdmin && <Route path='/list/moderation-logs' element={<Navigate to="/" replace />}/>}
          {currentAdmin.isAdmin && <Route path='/list/moderation-logs/:ml_id' element={<ModerationLogDetails />}/>}

          <Route path="/register" element={isAdmin ? <SignUp /> : <Navigate to="/" />} />

          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/logout" element={<LogoutHandler />} />

          {/* 404 Not Found */}
          <Route path='*' element={<NotFound />} />
        </Routes>

      </div>
      
    </div>
  )
}

export default App


/*

// Requests ///////////////////////////////////////////////////////////////////////////////////////

  Remove header, move Logout/Perms to sidebar

  Delete Navbar
  Move account/permissions to sidebar


// CORE FEATURES NEEDED ///////////////////////////////////////////////////////////////////////////


Planned -------------------------------------------------------------------------------------------



Backburner ----------------------------------------------------------------------------------------



// TODO NOW ///////////////////////////////////////////////////////////////////////////////////////

Maybe create an ('isForTesting') flag for posts, which will only display for testing accounts / the account that made the post.



// TODO LATER /////////////////////////////////////////////////////////////////////////////////////


  // Concepts ////////////

  Include number of report/moderation logs in User/Admin Details

  Make MyAccount an AdminDetails page. Will need code to allow Moderators to access their own details very specifically

  For Shadowban, exclude from queries EXCLUDING Profile/ID specific
  
  Cleanup & Standardize Admin Queries/Controllers  
  
  "Automated Moderation", Use algorithms to flag potentially harmful content for human review.

  "Appeals Process", Provide a mechanism for users to appeal moderation decisions, which helps in maintaining fairness.

  "Dynamic Filters: Depending on the complexity of your data and the needs of your users, you might also consider implementing dynamic filters that can adjust based on the data type of the selected field. For instance, for string fields, filters could include conditions like "contains", "starts with", etc. For integers and booleans, filters could include numerical ranges, exact matches, or boolean states."



// DOCUMENTATION //////////////////////////////////////////////////////////////////////////////////

General ------------------------------------

Analytics for each user (ip, country, etc)



PERMISSIONS OVERVIEW  ----------------------------

Owner - (All)

    - Create/Manage/Deactivate/Delete for ALL accounts


Admin - (Front and Backend)

    - Create/Manage/Deactivate/Delete for Moderator accounts (Not other Admins)


Moderator - (Frontend focus)

    - Ban Account (Account Deletion + IP Ban)

    - Deactivate Account (User cannot Post/Comment/Like/Etc)

    - Warn Account (?) (Adds a strike to the account for inappropriate activity)

    - Delete Posts/Comments (Not Edit?)





Pages -------------------------------------------

    ALL ACCESS |||

        Login
            - Login Form with email/username/password fields and a "Forgot Password" button.
            
            - Password Reset:
                - Keep logs of both the request and reset, including by who and when

                - Send an email with a link to reset the password.

                ("For higher security roles like Admins, consider requiring admin intervention for password resets. This adds an extra layer of security by ensuring that password resets for sensitive accounts are monitored and logged. 
                
                For regular users and Moderators, a self-service password reset is generally sufficient and reduces administrative overhead.")

        Overview
            - Start with a dashboard that provides a high-level overview of key metrics and recent activities. This could include statistics like total number of users, posts, comments, and any other relevant data.

        User List
            - List View: Display all users. (Includes search/filter)

            - Detail View: Show all activities, including posts, comments, likes(?), and any administrative actions taken by or against them. (Clicking on a specific Post/Comment/etc category from the User opens that tab with filters?)

        Posts 
            - List View: Display all posts. (Includes search/filter)

            - Detail View: Show content, edit history(?), and associated comments of the post in question.

        Comments
            - List View: Display all comments. (Includes search/filter)

            - Detail View: Detailed view for each comment, possibly linked to the original post for context.

        MyAccount
            - Show the details of the current account

        Logs/Activity
            - Maintain a log of all actions taken by admins for accountability.


    ADMIN ACCESS |||

        Admin List
            - List View: Display all Moderator/Admin/Owner accounts present. (Includes search/filter)

            - Detail View: Display more on the specifc account in question. If the permissions of the viewer is higher than that of the account being viewed, provide the ability to edit information. (Maybe also show all their logged actions?)

            
        Account Creation
            - Allow Owners/Admins to create new accounts. Provide a form for entering the necessary details, including username, email, and password.

            - Initial Password Setting:
                By Creator: When an account is created by an Owner or Admin, set an initial randomly generated temporary password.

                By New Account Holder: Upon first login, prompt the new account holder to change their password. This ensures that the password is known only to the account holder and is immediately personalized, enhancing security.

            - Email Verification:
                Send an email verification link to the new account's email address. Afterward, the user can set or reset their password.

            - Require a CAPTCHA for account creation(?)


    OWNER ACCESS |||

        - Currently N/A (Please give ideas if any)



UI Considerations -------------------------------------------

"Navigation: Use a clear, easy-to-navigate menu to switch between different sections. Consider using a sidebar for navigation with expandable menus for complex categories."

"Security: Secure the UI with appropriate authentication and ensure that sensitive actions are protected and logged."

"By providing both individual and aggregate views, your admin panel will be equipped to handle detailed investigations of user behavior as well as broader monitoring and reporting tasks. This approach enhances both the usability and functionality of the admin panel."


*/