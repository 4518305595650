import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Spinner from '../../components/spinner/Spinner'

const Overview = () => {
    const { getGenericAnalytics } = useAuth()
    const [analytics, setAnalytics] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)

        const fetchGenericAnalytics = async () => {
            const analytics = await getGenericAnalytics()
            // console.log(analytics)

            setAnalytics(analytics)
            setIsLoading(false)
        }
        fetchGenericAnalytics()
    }, [])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    return (
        // Load Screen
        isLoading ? <div className='overview-page page'><Spinner /></div>        
        :        
        // Page
        <div className='overview-page page'>

            <div className="overview-container">

                {/* Statistics */}
                <section className="overview-statistics list-section">

                    <h1 className="account-overview-title">Site Overview</h1>

                    <hr />

                    <ul>
                        {analytics.map(collection => (
                            <li key={collection.name}><span>{capitalizeFirstLetter(collection.name)}:</span> <span>{collection.count}</span></li>
                        ))}
                        {/* <li><span>Total Users:</span> <span>100</span></li>
                        <li><span>Total Posts:</span> <span>100</span></li>
                        <li><span>Total Comments:</span> <span>100</span></li>
                        <li><span>Total Likes:</span> <span>100</span></li> */}
                    </ul>

                </section>

                {/* Activity Log */}
                {/* <section className="activity-log list-section">
                    <h4 className="account-overview-title">Activity Log</h4>
                    <hr />
                </section> */}
            </div>
        </div>
    )
}

export default Overview
