import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Searchbar from '../../components/searchbar/Searchbar'
import { useAuth } from '../../context/AuthContext'
import { getListViewData } from '../../hooks/listViewHooks'
import DynamicTable from '../../components/dynamicTable/DynamicTable'
import Spinner from '../../components/spinner/Spinner'

const ListView = () => {
    const { collection } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const [tableData, setTableData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const { authCustomFetch } = useAuth()

    // const headers = tableData && tableData.length > 0 ? Object.keys(tableData[0]) : []
    // const searchHeaders = tableData && tableData?.list?.length > 0 
    //     ? Object.keys(tableData.list[0]).filter(key => 
    //         typeof tableData.list[0][key] === 'string' && !key.toLowerCase().includes('date') && key.toLowerCase() !== 'solution' && key.toLowerCase() !== 'problem'
    //     ).map(key => key.toLowerCase() === 'title' ? 'text' : key)
    //     : []
    // const sortHeaders = tableData && tableData?.list?.length > 0 ? Object.keys(tableData.list[0]).filter(key => typeof tableData.list[0][key] === 'number' || typeof tableData.list[0][key] === 'boolean') : []

    const getHeaderFilters = () => {
        let searchBy = ['_id']
        let sortBy = ["relevance","date"]


        switch(collection) {
            case 'users':
                searchBy.push(...['username', 'email'])
                sortBy.push(...['isEmailVerified', 'isShadowBanned', 'isMuted', "posts", "comments", "likes"])
                break
            case 'posts':
                searchBy.push(...['user_id', 'username', 'text']) // 'title',
                sortBy.push(...['likes', 'comments', 'isHidden'])
                break
            case 'comments':
                searchBy.push(...['user_id', 'username', 'post_id', 'parent_id', 'text'])
                sortBy.push(...['likes', 'replies', 'isHidden'])
                break
            case 'admins':
                searchBy.push(...['username', 'email', 'permissions'])
                sortBy.push(...['isEmailVerified', 'isDeactivated', 'permissions'])
                break
            case 'report-logs':
                searchBy.push(...['category', 'target_id', 'target_type','accuser_user_id', 'accuser_username', 'offender_user_id', 'offender_username'])
                sortBy.push(...['category', 'target_id', 'target_type'])
                break
            case 'moderation-logs':
                searchBy.push(...['action', 'target_id', 'target_type','admin_id', 'admin_username', 'offender_user_id', 'offender_username'])
                sortBy.push(...['action', 'target_id', 'target_type'])
                break
    
            default: break
        }
    
        return { searchBy, sortBy }
    }

    useEffect(() => {
        fetchData()
    }, [collection, location.search])    

    const fetchData = async () => {
        if (collection) {
            // console.log(collection)
            
            setIsLoading(true)

            const query = searchParams.get('query') || ""
            const category = searchParams.get('category') || "_id"
            const sort = searchParams.get('sort') || "date"
            const order = searchParams.get('order') || "desc"
            const page = searchParams.get('page') || 1

            const data = await getListViewData(collection, authCustomFetch, {
                query,
                category,
                sort,
                order,
                page
            })
            // console.log(data)

            setTableData(data)
            setIsLoading(false)
        }
        else setTableData(null)
    }

    const handlePageChange = (direction) => {
        const currentPage = parseInt(searchParams.get('page')) || 1
        const newPage = direction? currentPage + direction : 1
        // console.log(newPage, currentPage, direction)

        if (newPage >= 1 && newPage <= Math.ceil(tableData?.totalCount / tableData?.pageLimit)) {
            //Setting new page
            searchParams.set('page', newPage)
            navigate(`?${searchParams.toString()}`)
        }
    }

    return (
        <div className='listview-page page'>

            <header>
                <div className='header-top'>
                    <h2>{collection}</h2>
                    <Searchbar headerFilters={getHeaderFilters()}/>
                    {/* <Searchbar searchHeaders={searchHeaders} sortHeaders={sortHeaders}/> */}
                </div>
                <div className='header-bottom'>
                    {!isLoading && <span>Found {tableData?.totalCount || 0} results {searchParams.get('query') ? `for ${searchParams.get('query')}` : ''} in {collection}</span>}
                </div>
            </header>

            {isLoading ?

                // Load Screen
                <Spinner />

                :

                // TableData
                <div className="listview-container">
                    {tableData && <DynamicTable data={tableData?.list} />}
                </div>
            }

            {/* <Pagination /> */}
            {!isLoading && 
            <div className="pagination">
                <button className="pagination__previous-button button-small" onClick={() => handlePageChange(-1)}>Previous</button>
                <span className="pagination__page-count">{parseInt(searchParams.get('page')) || 1} of {Math.ceil(tableData?.totalCount / tableData?.pageLimit) || 1}</span>
                <button className="pagination__next-button button-small" onClick={() => handlePageChange(+1)}>Next</button>
            </div>}
        </div>
    )
}

export default ListView
