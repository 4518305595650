const baseURL = import.meta.env.VITE_BACKEND_URL

// GET
export const getProblemSolution = async (ps_id, authCustomFetch) => {

    try {
        // const queryString = new URLSearchParams(queryParams).toString()
        const url = `${baseURL}/admin_get_problemsolution/${ps_id}`
        const options = {method: 'GET'}

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN PROBLEM SOLUTION DATA RETRIEVAL:", error.message)
        return false
    }
}

// DELETE
export const deleteProblemSolution = async (ps_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_delete_problemsolution/${ps_id}`
        const options = {
            method: 'DELETE', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN PROBLEM SOLUTION DELETION:", error.message)
        return false
    }
}

// HIDE
export const toggleHideProblemSolution = async (ps_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_toggle_hide_problemsolution/${ps_id}`
        const options = {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN PROBLEM SOLUTION HIDING:", error.message)
        return false
    }
}