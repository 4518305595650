import { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useSearchParams } from 'react-router-dom'
import Spinner from '../../components/spinner/Spinner'

const VerifyEmail = () => {
  const [searchParams] = useSearchParams()
  const [token, setToken] = useState(searchParams.get('token'))

  const { verifyEmail, isLoggedIn } = useAuth()
  
  const [response, setResponse] = useState("Verifying...")
  const [isLoading, setIsLoading] = useState(true)
  

  useEffect(() => {
      const verifyToken = async () => {
        // console.log("token: " + token)
        const response = await verifyEmail(token)

        setResponse(response)
        setIsLoading(false)
      }

      verifyToken()
  }, [token])

  return (
    <div className='verifyemail-page'>
      {isLoading ? 
        <Spinner /> 
          
          :
          
        <div className='verifyemail-container'>
          <h1>Email Verification</h1>
            
          <div>
            <h4 className={`${response.success ? 'text-success' : 'text-danger'}`}>{response.message || response.error}</h4>
            {isLoggedIn ? <Link to='/'>Click here to proceed to Overview</Link> : <Link to='/login'>Click here to return to Login</Link>}
          </div>        

        </div>
      }
    </div>
  )
}

export default VerifyEmail
