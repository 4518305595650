import { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { getModerationLogs, deleteModerationLog } from '../../../hooks/moderationLogHooks'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'



const ModerationLogDetails = () => {
    // const navigate = useNavigate()

    const { ml_id } = useParams()
    const [moderationLogDetails, setModerationLogDetails] = useState()
    const [isDeleted, setIsDeleted] = useState(false)
    const { authCustomFetch, currentAdmin } = useAuth()
    const [isLoading, setIsLoading] = useState(true)

    let linkTargetType = moderationLogDetails?.target_type
    linkTargetType = moderationLogDetails?.target_type === "ProblemSolution" ? "Post" : linkTargetType

    const fetchmoderationLogDetails = async () => {
        setIsLoading(true)

        // Get ModerationLog
        const data = await getModerationLogs(ml_id, authCustomFetch)
        // console.log(data)

        // Set ModerationLog Details
        setModerationLogDetails(data?.list?.length === 1 ? data.list[0] : null)

        setIsLoading(false)
    }

    useEffect(() => {        
        fetchmoderationLogDetails()
    }, [ml_id])

    // Deletion Handler
    const handleDeleteModerationLog = async () => {
        if (window.confirm("Are you sure you want to delete this ModerationLog?")) {
            const response = await deleteModerationLog(ml_id, "", authCustomFetch)

            // navigate('/list/comments', {replace: true})
            // console.log(response)

            if (response.success) setIsDeleted(true)
            else alert(`Error deleting Moderation-Log: ${response.message || response.error}`)
        }
    }

    return (
        // Load Screen
        isLoading || !moderationLogDetails ? 
        <div className="page">
            {isLoading ? 
                <Spinner />

                :

                <h1>Moderation-Log not found</h1>
            }
        </div> 
        
        :

        // Main Page
        <div className='moderationlogdetails-page page'>
            <header>
                <h2>Moderation-Log Details</h2>
                <span className='text-secondary'>Showing ID: <span>'{moderationLogDetails?._id}'</span></span>
            </header>

            <div className="moderationlogdetails-container">

                <section className="moderationlogdetails-overview list-section">
                    <h4>Overview</h4>

                    <hr />

                    <ul>
                        <li><span>_id</span> <span>{moderationLogDetails?._id}</span></li>
                        <li><span>Moderator</span> <span><NavLink to={`/list/admins/${moderationLogDetails?.admin_id}`}>{moderationLogDetails?.moderator}</NavLink></span></li>
                        <li><span>Offender</span> <span><NavLink to={`/list/${moderationLogDetails?.target_type === "Admin" ? "admins" : "users"}/${moderationLogDetails?.target_user_id}`}>{moderationLogDetails?.offender}</NavLink></span></li>
                        <li><span>Date</span> <span>{moderationLogDetails?.date}</span></li>

                        {/* <li><span><NavLink to={`/list/comments?query=${moderationLogDetails?._id}&category=parent_id&sort=relevance&order=desc`}>Replies</NavLink></span> <span>{moderationLogDetails?.replies}</span></li> */}
                    </ul>
                </section>


                {/* <section className="postdetails-activity list-section">
                    <h4>Activity</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/`}>Comments</NavLink></span> <span>{postDetails?.commentCount}</span></li>
                        <li><span>Likes</span> <span>{postDetails?.likesGiven}</span></li>
                    </ul>
                </section> */}


                <section className="moderationlogdetails-moderation">
                    <h4>Moderation Controls</h4>

                    <hr />

                    {isDeleted ? <h1 className='text-danger'>{`Report-Log has been Deleted`}</h1>
                    
                        :

                        <ul>
                            {/* <li>
                                <button className={`${isHidden ? 'button-success' : 'button-danger'}`} onClick={handleToggleHideComment}>{isHidden ? 'Un-Hide' : 'Hide'} {commentType}</button> Deactivate account and hide from public view (Cannot post, comment, etc)
                                <p className="text-secondary">This will hide the post from public view and prevent it from being seen by other users.</p>
                            </li> */}
                            <li>
                                <button className="button-danger" onClick={handleDeleteModerationLog} disabled={!currentAdmin.isAdmin}>Delete Report-Log</button>
                                <p className="text-secondary">This will permanently delete the report-log and all associated data.</p>
                            </li>
                        </ul>
                    }

                </section>


                <section className="moderationlogdetails-content list-section">
                    <h4>Content</h4>

                    <hr />

                    <ul >
                        <li>
                            <p className='moderationlogdetails-content-target'><span className='text-secondary'>Target:</span> <NavLink to={`/list/${linkTargetType.toLowerCase()}s/${moderationLogDetails?.target_id}`}>{linkTargetType} ( _id: {moderationLogDetails?.target_id} )</NavLink></p>
                        </li>
                        <li>
                            <p className='moderationlogdetails-content-action'><span className='text-secondary'>Action:</span> {moderationLogDetails?.action}</p>
                        </li>
                    </ul>

                    <div className='moderationlogdetails-content-text'>

                            <div className="moderationlogdetails-content-text-container" dangerouslySetInnerHTML={{ __html: moderationLogDetails?.reason}} />

                    </div>
                    


                </section>

            </div>
        </div>
    )
}

export default ModerationLogDetails
