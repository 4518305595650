import { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { getAdmin, deleteAdmin, togglePermissionsAdmin } from '../../../hooks/adminHooks'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'

const AdminDetails = () => {
    const { admin_id } = useParams()
    const [adminDetails, setAdminDetails] = useState()
    const [isDeleted, setIsDeleted] = useState(false)
    const { authCustomFetch, currentAdmin } = useAuth()
    const [isLoading, setIsLoading] = useState(true)

    const isDeactivated = adminDetails?.isDeactivated


    const fetchAdminDetails = async () => {
        setIsLoading(true)

        const data = await getAdmin(admin_id, authCustomFetch)
        // console.log(data)

        // Set Admin Details
        setAdminDetails(data?.list?.length === 1 ? data.list[0] : null)

        setIsLoading(false)
    }

    useEffect(() => {        
        fetchAdminDetails()
    }, [admin_id])

    // Deletion Handler
    const handleDeleteAdmin = async () => {
        const reason = window.prompt(`Please enter the reason for deleting this Admin.`)
        if (reason) {
            const response = await deleteAdmin(admin_id, reason, authCustomFetch)

            // navigate('/list/comments', {replace: true})
            if (response.success) setIsDeleted(true)
            else alert(`Error deleting Admin: ${response.message || response.error}`)
        }
    }

    // Permissions Handler
    const handleTogglePermissionsAdmin = async () => {
        const confirmation = isDeactivated ? window.confirm(`Are you sure you want to restore permissions for this Admin?`) : prompt(`Please enter the reason for revoking permissions for this Admin.`)
        
        if (confirmation) {
            const reason = isDeactivated ? null : confirmation
            await togglePermissionsAdmin(admin_id, reason, authCustomFetch)
            fetchAdminDetails()
        }
    }

    // Permissions Check
    // const isPermissionsAdmin = currentAdmin.isAdmin && adminDetails.permissions === "Admin"

    return (
        // Load Screen
        isLoading || !adminDetails ? 
        <div className="page">
            {isLoading ? 
                <Spinner />

                :

                <h1>Admin not found</h1>
            }
        </div> 
        
        :

        // Page
        <div className='admindetails-page page'>
            <header>
                <h2>Admin Details</h2>
                <span className='text-secondary'>Showing ID: <span>'{adminDetails?._id}'</span></span>
            </header>

            <div className="admindetails-container">

                <section className="admindetails-account list-section">
                    <h4>Account</h4>

                    <hr />

                    <ul>
                        <li><span>_id</span> <span>{adminDetails?._id}</span></li>
                        <li><span>Username</span> <span>{adminDetails?.username}</span></li>
                        <li><span>Email</span> <span>{adminDetails?.email}</span></li>
                        <li><span>Verified</span> <span>{adminDetails?.isEmailVerified ? "True" : "False"}</span></li>
                        <li><span>Registration</span> <span>{adminDetails?.date_created}</span></li>
                    </ul>
                </section>

                {/* <section className="admindetails-activity list-section">
                    <h4>Activity</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/`}>Posts Written</NavLink></span> <span>{adminDetails?.problemSolutionCount}</span></li>
                        <li><span><NavLink to={`/`}>Actions</NavLink></span> <span>{adminDetails?.actions}</span></li>
                    </ul>
                </section> */}

                <section className="admindetails-disciplinary list-section">
                    <h4>Disciplinary</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/`}>Moderation Logs</NavLink></span> <span>X</span></li>
                        <li><span>Active</span> <span>{adminDetails?.isActive ? "Yes" : "No"}</span></li>
                        {/* <li><span>Banned</span> <span>{adminDetails?.isBanned ? "Yes" : "No"}</span></li> */}
                    </ul>
                </section>

                <section className="admindetails-moderation">
                    <h4>Moderation Controls</h4>

                    <hr />

                    {isDeleted ? <h1 className='text-danger'>{`Admin has been Deleted`}</h1>
                    
                    :

                    <ul>
                        {/* Warn account for inappropriate behavior */}
                        {/* <li>
                            <button className="button-danger">Warn Account</button> 
                            <p className="text-secondary">This will warn the account for inappropriate behavior. After 3 warnings, the account will be deactivated and reviewed by moderators.</p>
                        </li> */}
                        <li>
                            <button className={isDeactivated ? "button-success" : "button-danger"} onClick={handleTogglePermissionsAdmin} disabled={!(currentAdmin.isAdmin && (adminDetails.permissions === "Owner" || adminDetails.permissions === "Admin") && isDeactivated) && !(currentAdmin.isAdmin && (adminDetails.permissions === "Moderator" || adminDetails.permissions === "Admin"))}>{isDeactivated ? "Restore Permissions" : "Revoke Permissions"}</button> {/* Deactivate account and hide from public view (Cannot post, comment, etc) */}
                            <p className="text-secondary">This will revoke account permissions and prevent any actions from being taken.</p>
                        </li>
                        <li>
                            <button className="button-danger" onClick={handleDeleteAdmin} disabled={!currentAdmin.isOwner}>Delete Account</button> {/* IP-Ban and deletion of all activity (?) */}
                            <p className="text-secondary">This will delete the account, revoking permissions and removing the Admin from the system.</p>
                        </li>
                    </ul>}
                </section>

            </div>
        </div>
    )
}

export default AdminDetails
