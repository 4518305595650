import { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { getReportLog, deleteReportLog } from '../../../hooks/reportLogHooks'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'



const ReportLogDetails = () => {
    // const navigate = useNavigate()

    const { reportlog_id } = useParams()
    const [reportLogDetails, setReportLogDetails] = useState()
    const [isDeleted, setIsDeleted] = useState(false)
    const { authCustomFetch, currentAdmin } = useAuth()
    const [isLoading, setIsLoading] = useState(true)

    const fetchReportLogDetails = async () => {
        setIsLoading(true)

        // Get ReportLog
        const data = await getReportLog(reportlog_id, authCustomFetch)
        // console.log(data)

        // Set ReportLog Details
        setReportLogDetails(data?.list?.length === 1 ? data.list[0] : null)

        setIsLoading(false)
    }

    useEffect(() => {        
        fetchReportLogDetails()
    }, [reportlog_id])

    // Deletion Handler
    const handleDeleteReportLog = async () => {
        const reason = window.prompt(`Please enter the reason for deleting this ReportLog`)
        if (reason) {
            const response = await deleteReportLog(reportlog_id, reason, authCustomFetch)

            // navigate('/list/comments', {replace: true})
            // console.log(response)

            if (response.success) setIsDeleted(true)
            else alert(`Error deleting Report-Log: ${response.message || response.error}`)
        }
    }

    return (
        // Load Screen
        isLoading || !reportLogDetails ? 
        <div className="page">
            {isLoading ? 
                <Spinner />

                :

                <h1>Report-Log not found</h1>
            }
        </div> 
        
        :

        // Main Page
        <div className='reportlogdetails-page page'>
            <header>
                <h2>Report-Log Details</h2>
                <span className='text-secondary'>Showing ID: <span>'{reportLogDetails?._id}'</span></span>
            </header>

            <div className="reportlogdetails-container">

                <section className="reportlogdetails-overview list-section">
                    <h4>Overview</h4>

                    <hr />

                    <ul>
                        <li><span>_id</span> <span>{reportLogDetails?._id}</span></li>
                        <li><span>Accuser</span> <span><NavLink to={`/list/users/${reportLogDetails?.accuser}`}>{reportLogDetails?.accuser}</NavLink></span></li>
                        <li><span>Offender</span> <span><NavLink to={`/list/users/${reportLogDetails?.offender}`}>{reportLogDetails?.offender}</NavLink></span></li>
                        <li><span>Date</span> <span>{reportLogDetails?.date}</span></li>

                        {/* <li><span><NavLink to={`/list/comments?query=${reportLogDetails?._id}&category=parent_id&sort=relevance&order=desc`}>Replies</NavLink></span> <span>{reportLogDetails?.replies}</span></li> */}
                    </ul>
                </section>


                {/* <section className="postdetails-activity list-section">
                    <h4>Activity</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/`}>Comments</NavLink></span> <span>{postDetails?.commentCount}</span></li>
                        <li><span>Likes</span> <span>{postDetails?.likesGiven}</span></li>
                    </ul>
                </section> */}


                <section className="reportlogdetails-moderation">
                    <h4>Moderation Controls</h4>

                    <hr />

                    {isDeleted ? <h1 className='text-danger'>{`Report-Log has been Deleted`}</h1>
                    
                        :

                        <ul>
                            {/* <li>
                                <button className={`${isHidden ? 'button-success' : 'button-danger'}`} onClick={handleToggleHideComment}>{isHidden ? 'Un-Hide' : 'Hide'} {commentType}</button> Deactivate account and hide from public view (Cannot post, comment, etc)
                                <p className="text-secondary">This will hide the post from public view and prevent it from being seen by other users.</p>
                            </li> */}
                            <li>
                                <button className="button-danger" onClick={handleDeleteReportLog} disabled={!currentAdmin.isAdmin}>Delete Report-Log</button>
                                <p className="text-secondary">This will permanently delete the report-log and all associated data.</p>
                            </li>
                        </ul>
                    }

                </section>


                <section className="reportlogdetails-content list-section">
                    <h4>Content</h4>

                    <hr />

                    <ul >
                        <li>
                            <p className='reportlogdetails-content-target'><span className='text-secondary'>Target:</span> <NavLink to={`/list/${reportLogDetails?.target_type.toLowerCase()}s/${reportLogDetails?.target_id}`}>{reportLogDetails?.target_type} ( _id: {reportLogDetails?.target_id} )</NavLink></p>
                        </li>
                        <li>
                            <p className='reportlogdetails-content-category'><span className='text-secondary'>Category:</span> {reportLogDetails?.category}</p>
                        </li>
                    </ul>

                    <div className='reportlogdetails-content-text'>

                            <div className="reportlogdetails-content-text-container" dangerouslySetInnerHTML={{ __html: reportLogDetails?.reason}} />

                    </div>
                    


                </section>

            </div>
        </div>
    )
}

export default ReportLogDetails
