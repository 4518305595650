const baseURL = import.meta.env.VITE_BACKEND_URL

export const getUserAccount = async (userId, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_get_user_account/${userId}`
        const options = {method: 'GET'}

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN USER ACCOUNT DATA RETRIEVAL:", error.message)
        return false
    }
}

// DELETE
export const deleteUser = async (userId, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_delete_user/${userId}`
        const options = {
            method: 'DELETE', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN USER DELETION:", error.message)
        return false
    }
}

// MUTE
export const toggleMuteUser = async (userId, reason, expirationTime, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_toggle_mute_user/${userId}`
        const options = {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                expirationTime,
                reason
            })
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN USER MUTING:", error.message)
        return false
    }
}

// SHADOWBAN
export const toggleShadowBanUser = async (userId, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_toggle_shadowban_user/${userId}`
        const options = {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reason
            })
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN USER SHADOWBANNING:", error.message)
        return false
    }
}