import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import { limitTextLength, validatePassword } from '../../helpers/helper'

const SignUp = () => {
    const { signup, currentAdmin } = useAuth()
    
    const [state, setState] = useState("Register")
    const [errorMessage, setErrorMessage] = useState("")
    const [submitFailed, setSubmitFailed] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const [formInput, setFormInput] = useState({
        username: "",
        permissions: "Moderator",
        password: "12345678Q@",
        email: "vonglory176@gmail.com",
    })

    // Validates the passwords during SignUp
    useEffect(() => {
        const {password} = formInput

        if (password) {
            const {success, message} = validatePassword(password, null)
            // console.log(success, message)
            setErrorMessage(success? '' : message)
        }
    }, [formInput])

    // Handles Form Submission
    const handleSubmit = async (e) => {
        e.preventDefault()

        // Sends data to AuthContext
        const {username, permissions, email, password} = formInput
        const {success, message} = validatePassword(password, null)
        
        let response
        if (success) response = await signup(username, permissions, email, password)
        if (response === false) setState("Email Verification")
        
        
        // If an error is returned, set the error message
        // console.log(response) 

        if (response) {
            setErrorMessage(response)
            setSubmitFailed(true)
        }
    }
    
    // Swaps Modal between Login and Sign Up
    useEffect(() => {
        setErrorMessage("")
        setSubmitFailed(false)
    }, [state])

    // Records form data
    const changeHandler = (e) => {
        const maxCharacters = e.target.name === "username" ? 30 : 50
        setFormInput({...formInput, [e.target.name]: limitTextLength(e.target.value, maxCharacters)})
    }
    
    return (

    <div className='signup-page page'> {/* page */}

        <div className={`signup-container ${state === "Email Verification" ? "show-verification-message" : ""}`}>

            {/* Header */}
            <header className="signup-header">
                <h1>{state}</h1>
            </header>

            <hr />

            {state === "Email Verification" ?

            // Email Verification Message
            <div>
                <p>We've sent a email to your inbox at '{formInput.email}'</p>
                <p className='text-secondary'>Please check your inbox for a link to verify your account</p>
                <p className='text-secondary'>(If you don't see it, please try logging in again in a few minutes)</p>
                <hr />

                <div className="signup-state-swapper">
                    <p>Return to signup <span onClick={() => {setState("Register")}}>Click here</span></p>
                </div>
            </div>

            :

            // signup Form
            <div className='signup-form-container'>

                {/* Form */}
                <form className={`signup-form ${submitFailed && "signup-error"}`} onSubmit={handleSubmit}>

                    {/* Username (For registration only) */}
                    <label htmlFor="username" className='text-secondary'>Username</label>
                    <input name="username" value={formInput.username} onChange={changeHandler} type="text" placeholder='Your Name' />

                    {/* Email */}
                    <label htmlFor="email" className='text-secondary'>Email Address</label>
                    <input name="email" value={formInput.email} onChange={changeHandler} type="email" placeholder='Email Address' />

                    {/* Error Message (If present) */}
                    {errorMessage && <p className='signup-error-text'>{errorMessage}</p>}

                    {/* Password */}
                    {state !== "Reset Password" && <>
                        <label htmlFor="password" className='text-secondary'>Password</label>
                        <div className="password-input-container">
                            <input name="password" value={formInput.password} onChange={changeHandler} type={showPassword ? "text" : "password"} placeholder='Password' /> {/* placeholder='Enter your Password...' */}

                            <button aria-label='Toggle Password Visibility' name='show-password-toggle' type="button" onClick={() => setShowPassword(!showPassword)} className="password-visibility-toggle">
                                {showPassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}
                            </button>
                        </div>
                    </>}

                    {/* Permissions (For registration only) */}
                    <label htmlFor="permissions" className='text-secondary'>Permissions</label>
                    <select name="permissions" value={formInput.permissions} onChange={changeHandler}>
                        <option value="Moderator" selected>Moderator</option>
                        <option value="Admin">Admin</option>
                        {currentAdmin.isOwner && <option value="Owner">Owner</option>}
                    </select>

                    <button type='submit' disabled={!currentAdmin.isAdmin}>Continue</button>

                </form>

                {/* Privacy policy checkbox */}
                {/* <div className="signup-agree">
                <input type="checkbox" name='' id='' />
                <p>By continuing, you agree to our <span>Terms of Service</span> and <span>Privacy Policy</span></p>
                </div> */}
            </div>}
        </div>
    </div>
    )
}

export default SignUp