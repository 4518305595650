export const validatePassword = (password, confirmPassword) => {
    const hasEightCharacters = password.length >= 8
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password)
    const hasUpperCase = /[A-Z]/.test(password)
    const hasNumber = /[0-9]/.test(password)

    // Bypass confirmation if second password is null
    const passwordsMatch = confirmPassword === null || (password === confirmPassword)

    // console.log(
    //     password,
    //     confirmPassword,
    //     passwordsMatch
    // )

    if (!hasEightCharacters) {
        return { success: false, message: "Password must be at least 8 characters long." }
    }
    if (!hasSpecialCharacter) {
        return { success: false, message: "Password must contain at least one special character." }
    }
    if (!hasUpperCase) {
        return { success: false, message: "Password must contain at least one uppercase letter." }
    }
    if (!hasNumber) {
        return { success: false, message: "Password must contain at least one number." }
    }
    if (!passwordsMatch) {
        return { success: false, message: "Passwords do not match." }
    }

    return { success: true, message: "Password is valid." }
}

export const limitTextLength = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) : text;
}