import {useState, useEffect, useRef} from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useSearchParams } from 'react-router-dom'
import Spinner from '../../components/spinner/Spinner'
import { validatePassword } from '../../helpers/helper'

const ResetPassword = () => {
    const [searchParams] = useSearchParams()
    const [token, setToken] = useState(searchParams.get('token'))

    const { verifyPasswordResetToken, resetPassword, isLoggedIn } = useAuth()

    const [resetFinished, setResetFinished] = useState(false)
    const [resetResponse, setResetResponse] = useState('')

    const [validationResponse, setValidationResponse] = useState('')
    const [validationFailed, setValidationFailed] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [formInput, setFormInput] = useState({
        password: '',
        confirmPassword: ''
    })

    const passwordInputRef = useRef(null)

    // Verifies the token
    useEffect(() => {
        const verifyToken = async () => {
            // console.log("token: " + token)
            const response = await verifyPasswordResetToken(token)
            // console.log(response)

            if (!response.success) setResetFinished(true) // Something went wrong, skip the form
            setResetResponse(response)

            setIsLoading(false)
        }
        verifyToken()
    }, [token])

    // Updates the form input
    const handleFormChange = (e) => {
        setFormInput({
            ...formInput,
            [e.target.name]: limitTextLength(e.target.value, 30)
        })
    }

    // Validates the passwords
    useEffect(() => {
        const {password, confirmPassword} = formInput

        if (password || confirmPassword) {
            const {success, message} = validatePassword(password, confirmPassword)
            setValidationResponse(!success? {message} : '')
        }

    }, [formInput])

    // Resets the password
    const handleResetPassword = async (e) => {
        e.preventDefault()
        const {password, confirmPassword} = formInput
        const {success, message} = validatePassword(password, confirmPassword)

        setValidationResponse(!success? {message} : '')

        if(success) {
            // console.log("token: " + token)
            const response = await resetPassword(token, formInput.password)

            setResetResponse(response)
            setResetFinished(true)
        }
        else {
            setValidationFailed(true)
            passwordInputRef.current.focus()
        }
    }

    return (
        <div className='resetpassword-page'>

            {isLoading? 

                <Spinner />
                :
                <div className={"resetpassword-container" + (resetFinished? " reset-finished" : "")}>
                    <h1>Password Reset</h1>
                    {!resetFinished? 
                        <form onSubmit={handleResetPassword} className={`resetpassword-form ${validationFailed? "validation-failed" : ""}`}>
                            <input ref={passwordInputRef} type="password" name="password" value={formInput.password} onChange={handleFormChange} placeholder="New Password" />
                            {validationResponse && <p className='text-danger'>{validationResponse.message}</p>}
                            <input type="password" name="confirmPassword" value={formInput.confirmPassword} onChange={handleFormChange} placeholder="Confirm Password" />

                            <div className="resetpassword-form-footer">
                                <button type="submit" >Continue</button>
                            </div>
                        </form>

                        :

                        <div className="resetpassword-finished">
                            <h4 className={`${resetResponse.success? "text-success" : "text-danger"}`}>{resetResponse.message || resetResponse.error}</h4>
                            {isLoggedIn ? <Link to='/account'>Click here to return to MyAccount</Link> : <Link to='/login'>Click here to return to Login</Link>}
                        </div>
                    }
                </div>
            }

        </div>
    )
}

export default ResetPassword
