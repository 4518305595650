import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

const DynamicTable = ({ data }) => {
    // Extract keys from the first object to use as column headers
    // console.log(data)
    const headers = data?.length > 0 ? Object.keys(data[0]) : []
    const {collection} = useParams()

    const ignoreHeaders = ['admin_id', 'user_id', 'target_user_id']

    return (
        <div className="dynamictable">

            <table>
                <thead>
                    <tr>
                        {headers.map(header => {

                            // IGNORE HEADERS
                            if (ignoreHeaders.includes(header)) return

                            return <th key={header} className='text-secondary'>{header}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.success !== false && data?.map((item, index) => {
                        // console.log(item)
                        const isAdmin = Boolean(item.permissions || item.target_type === "Admin")
                        const user_id = item.user_id || item.admin_id || item._id
                        const target_user_id = item.target_user_id || item.target_id
                        const target_type = `${item.target_type?.toLowerCase()}s` || false
                        const type = item.type || false


                        // TABLE ROW
                        return (
                        <tr key={index}> {/* onClick={() => handleRowClick(item)} */}

                            {headers.map(header => {
                                const value = item[header]
                                const isFirst = headers.indexOf(header) === 0
                                // const isAnId = header.includes('_id')

                                // USERNAMES
                                const usernameHeaders = ['username', 'accuser', 'moderator']
                                const isUsername = usernameHeaders.includes(header)

                                // TARGET USERNAMES (ReportLog Offender)
                                const targetUsernameHeaders = ['offender']
                                const isTargetUsername = targetUsernameHeaders.includes(header)
                                
                                // if(isTargetUsername)console.log(value, target_user_id)

                                // TARGET TYPE
                                const isTargetId = header === 'target_id'
                                
                                // IDS
                                const isParentId = header === 'parent_id'
                                const isPostId = header === 'post_id'

                                // TYPE
                                // const isComment = type === 'Comment'
                                // const isType = header === 'type'

                                // IGNORE HEADERS
                                if (ignoreHeaders.includes(header)) return
                                
                                return (
                                // TABLE CELL
                                <td key={`${index}-${header}`} data-label={header} title={isFirst? false : value}>
                                    <span>

                                        {
                                            isFirst ? // "_id" is ALWAYS first

                                            <NavLink to={`${value}`} title={`View ${type? type: collection} details`}>{value}</NavLink>
                                            
                                            :
                                            
                                            isUsername ?

                                            <NavLink to={`/list/${isAdmin || header === 'moderator' ? 'admins' : 'users'}/${user_id}`} title='View user details'>{value}</NavLink>

                                            :

                                            isTargetUsername ?

                                                target_user_id ?

                                                    <NavLink to={`/list/${isAdmin ? `admins/${target_user_id}` : `users/${target_user_id}`}`} title='View user details'>{value}</NavLink>
                                                    :
                                                    <NavLink to={`/list/${isAdmin ? `admins` : `users`}/${target_user_id}`} title='View user details'>{value}</NavLink>

                                            :

                                            isTargetId ?
                                            <NavLink to={`/list/${target_type}/${value}`} title='View user details'>{value}</NavLink>

                                            :

                                            isParentId ? // Comment Parent
                                            <NavLink to={`/list/comments/${value}`} title={`View parent comment details`}>{value}</NavLink>
                                            // <NavLink to={`/list/${isComment ? 'posts' : 'comments'}/${value}`} title={`View ${type} parent details`}>{value}</NavLink>

                                            :

                                            isPostId ? // PS Parent
                                            <NavLink to={`/list/posts/${value}`} title={`View parent post details`}>{value}</NavLink>

                                            :

                                            typeof value !== 'boolean' ?  value : value ? "True" : "False"
                                        }

                                    </span>

                                </td>
                                )
                            })}
                        </tr>
                        )
                    })}
                </tbody>
            </table>

        </div>
    )
}

export default DynamicTable