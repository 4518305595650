import { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { getUserAccount, toggleMuteUser, toggleShadowBanUser, deleteUser } from '../../../hooks/userHooks'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'

const UserDetails = () => {
    const { user_id } = useParams()
    const [userDetails, setUserDetails] = useState()
    const { authCustomFetch, currentAdmin } = useAuth()
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleted, setIsDeleted] = useState(false)

    const isMuted = userDetails?.isMuted?.duration // > new Date()
    const isShadowBanned = userDetails?.isShadowBanned

    // Checking current (LoggedIn) Admin permissions

    const fetchUserDetails = async () => {
        setIsLoading(true)

        const data = await getUserAccount(user_id, authCustomFetch)
        // console.log(data)

        // Set User Details
        setUserDetails(data?.length === 1 ? data[0] : null)

        setIsLoading(false)
    }

    useEffect(() => {        
        fetchUserDetails()
    }, [user_id])

    const formatTimeDifference = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}hr${hours !== 1 ? 's' : ''}` + `${minutes > 0 ? " and " + minutes + "min" : ''}`;
    }

    // Deletion Handler
    const handleDeleteUser = async () => {
        const reason = window.prompt(`Please enter the reason for deleting this User.`)
        if (reason) {
            const response = await deleteUser(user_id, reason, authCustomFetch)

            // navigate('/list/comments', {replace: true})
            if (response.success) setIsDeleted(true)
            else alert(`Error deleting User: ${response.message || response.error}`)
        }
    }

    // Mute Handler
    const handleToggleMuteUser = async () => {
        const confirmation = isMuted ? window.confirm(`Are you sure you want to un-mute this User?`) : prompt(`Please enter the reason for muting this User.`)

        if (confirmation) {
            const reason = isMuted ? null : confirmation
            const expirationTime = isMuted ? null : prompt(`Please enter the amount of time (in days) for which you would like to mute this User.`)
            
            if (expirationTime || expirationTime === null) {
                await toggleMuteUser(user_id, reason, expirationTime, authCustomFetch)
                fetchUserDetails()
            }
        }
    }

    // ShadowBan Handler
    const handleToggleShadowBanUser = async () => {
        const confirmation = isShadowBanned ? window.confirm(`Are you sure you want to un-shadowban this User?`) : prompt(`Please enter the reason for shadowbanning this User.`)
        
        if (confirmation) {
            const reason = isShadowBanned ? null : confirmation
            await toggleShadowBanUser(user_id, reason, authCustomFetch)
            fetchUserDetails()
        }
    }

    return (
        // Load Screen
        isLoading || !userDetails ? 
        <div className="page">
            {isLoading ? 
                <Spinner />

                :

                <h1>{`User not found`}</h1>
            }
        </div>
        
        :

        // Page
        <div className='userdetails-page page'>
            <header>
                <h2>User Details</h2>
                <span className='text-secondary'>Showing ID: <span>'{userDetails?._id}'</span></span>
            </header>

            <div className="userdetails-container">

                <section className="userdetails-account list-section">
                    <h4>Account</h4>

                    <hr />

                    <ul>
                        <li><span>_id</span> <span>{userDetails?._id}</span></li>
                        <li><span>Username</span> <span>{userDetails?.username}</span></li>
                        <li><span>Email</span> <span>{userDetails?.email}</span></li>
                        <li><span>Verified</span> <span>{userDetails?.isEmailVerified ? "True" : "False"}</span></li>
                        <li><span>Registration</span> <span>{userDetails?.date_created}</span></li>
                    </ul>
                </section>

                <section className="userdetails-activity list-section">
                    <h4>Activity</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/list/posts?query=${userDetails?._id}&category=user_id&sort=relevance&order=desc`}>Posts Written</NavLink></span> <span>{userDetails?.problemSolutionCount}</span></li>
                        <li><span><NavLink to={`/list/comments?query=${userDetails?._id}&category=user_id&sort=relevance&order=desc`}>Comments Written</NavLink></span> <span>{userDetails?.commentCount}</span></li>
                        <li><span>Likes Given</span> <span>{userDetails?.likesGiven}</span></li>
                        <li><span>Likes Received</span> <span>X</span></li>
                    </ul>
                </section>

                <section className="userdetails-disciplinary list-section">
                    <h4>Disciplinary</h4>

                    <hr />

                    <ul>
                        <li><span><NavLink to={`/`}>Moderation Logs</NavLink></span> <span>X</span></li>
                        <li><span>ShadowBanned</span> <span>{userDetails?.isShadowBanned ? "True" : "False"}</span></li>
                        <li><span>Muted</span> <span>{isMuted ? "True" : "False"}</span></li>
                        {isMuted && <li><span>Mute Duration</span> <span>{formatTimeDifference(userDetails?.isMuted?.duration)}</span></li>}
                        {isMuted && <li><span>Mute Expiration</span> <span>{userDetails?.isMuted?.expirationDate}</span></li>}
                    </ul>
                </section>

                <section className="userdetails-moderation">
                    <h4>Moderation Controls</h4>

                    <hr />

                    {isDeleted ? <h1 className='text-danger'>{`User has been Deleted`}</h1>
                    
                    :

                    <ul>
                        <li>
                            <button className={`${isMuted ? 'button-success' : 'button-danger'}`} onClick={handleToggleMuteUser}>{isMuted ? 'Un-Mute Account' : 'Mute Account'}</button> {/* Warn account for inappropriate behavior */}
                            <p className="text-secondary">This will mute the Account for a specified amount of time, preventing any posting or commenting, etc. NOTE: The reason given for Muting WILL be shown to the user.</p>
                        </li>
                        <li>
                            <button className={`${isShadowBanned ? 'button-success' : 'button-danger'}`} onClick={handleToggleShadowBanUser}>{isShadowBanned ? 'Un-ShadowBan Account' : 'ShadowBan Account'}</button> {/* Deactivate account and hide from public view (Cannot post, comment, etc) */}
                            <p className="text-secondary">This will hide the Account from public view, including all Post and Comment activity.</p>
                        </li>
                        <li>
                            <button className="button-danger" onClick={handleDeleteUser} disabled={!currentAdmin.isAdmin}>Delete Account</button>
                            <p className="text-secondary">This will PERMANENTLY delete the Account, including all associated Posts, Comments, Replies and Likes, etc.</p>
                        </li>
                    </ul>}
                </section>

            </div>
        </div>
    )
}

export default UserDetails