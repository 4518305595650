import React from 'react'

// Basic spinning load icon
const Spinner = () => {
  return (
    <div className="loading-icon">
        <i className="fa-solid fa-spinner fa-2x"></i>
    </div>
  )
}

export default Spinner
