const baseURL = import.meta.env.VITE_BACKEND_URL

// GET
export const getComment = async (comment_id, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_get_comment/${comment_id}`
        const options = {method: 'GET'}

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN COMMENT DATA RETRIEVAL:", error.message)
        return false
    }
}

// DELETE
export const deleteComment = async (comment_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_delete_comment/${comment_id}`
        const options = {
            method: 'DELETE', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN COMMENT DELETION:", error.message)
        return false
    }
}

// HIDE
export const toggleHideComment = async (comment_id, reason, authCustomFetch) => {

    try {
        const url = `${baseURL}/admin_toggle_hide_comment/${comment_id}`
        const options = {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({reason})
        }

        const data = await authCustomFetch(url, options)
        return data

    } catch (error) {
        // console.error("ERROR IN COMMENT HIDING:", error.message)
        return false
    }
}