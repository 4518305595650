import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

import company_logo from '../../assets/problemsolveradmin-logo.webp'

const SideNav = () => {

const { currentAdmin } = useAuth()

  return (
    <nav className='sidenav'>
        {/* <h3>Navigation</h3>
        <hr /> */}

        {/* Logo */}
        <Link to="/" className="header-logo-link">
            <img src={company_logo} alt="The ProblemSolver company logo" className='header-logo' />
        </Link>

        <hr />
          
          {/* MyAccount button */}
          <div className="sidenav-links-group">
            <h5 className='text-secondary'>Current User</h5>
            <Link to={`/list/admins/${currentAdmin._id}`} className="account-status btn">
                <p><span className='text-secondary'>Account: </span>'{currentAdmin.username}'</p>
                <p><span className='text-secondary'>Permissions: </span>'{currentAdmin.permissions}'</p>
            </Link>
          </div>

        <hr />

        <div className="sidenav-links-group">
            <h5 className='text-secondary'>General</h5>
            <ul>
                <li>
                    <NavLink to='/' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Overview</NavLink>
                </li>                
                {/* <li>
                    <NavLink to='/activity-logs' className='sidenav-link'>Activity Logs</NavLink>
                </li> */}
            </ul>
        </div>

        <hr />

        <div className="sidenav-links-group">
            <h5 className='text-secondary'>Front-End</h5>
            <ul>
                <li>
                    <NavLink to='/list/users?query=&category=_id&sort=relevance&order=desc&page=1' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Users</NavLink>
                </li>
                <li>
                    <NavLink to='/list/posts?query=&category=_id&sort=relevance&order=desc&page=1' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Posts</NavLink>
                </li>
                <li>
                    <NavLink to='/list/comments?query=&category=_id&sort=relevance&order=desc&page=1' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Comments</NavLink>
                </li>
                <li>
                    <NavLink to='/list/report-logs?query=&category=_id&sort=relevance&order=desc&page=1' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Report-Logs</NavLink>
                </li>
            </ul>
        </div>

        {currentAdmin.isAdmin && <hr />}
        
        { currentAdmin.isAdmin &&
        <div className="sidenav-links-group">
            <h5 className='text-secondary'>Back-End</h5>
            <ul>
                <li>
                    <NavLink to='/list/admins?query=&category=_id&sort=relevance&order=desc&page=1' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Admins</NavLink>
                </li>
                <li>
                    <NavLink to='/register' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Register</NavLink>
                </li>
                <li>
                    <NavLink to='/list/moderation-logs?query=&category=_id&sort=relevance&order=desc&page=1' className={({ isActive }) => `sidenav-link ${isActive ? 'isActive' : ''}`}>Mod-Logs</NavLink>
                </li>
                {/* <li>
                    <NavLink to='/logout' className={({ isActive }) => isActive ? 'sidenav-link isActive' : 'sidenav-link'}>Logout</NavLink>
                </li> */}
            </ul>            
        </div> }

        <hr />

        <div className="sidenav-links-group">
            {/* <h5 className='text-secondary'>Logout</h5> */}
            <ul>
                <li>
                    <NavLink to='/logout' className={({ isActive }) => isActive ? 'sidenav-link isActive' : 'sidenav-link'}>Logout</NavLink>
                </li>
            </ul>
        </div>
        
    </nav>
  )
}

export default SideNav
